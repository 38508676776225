import $ from 'jquery'

function showHideInternalNav(event) {
  $('.links-bar').toggleClass('is-active');
  $('.js-mobile-internal').toggleClass('is-active');
  $(".js-mobile-arrow").toggleClass('is-active');
  $('.js-mobile-internal:not(.category-single-nav-item)').addClass('js-scroll-to');
  $('.links-bar').attr('aria-expanded',
    $('.links-bar').attr('aria-expanded')=='false' ? 'true' : 'false'
  );
}

export default function initInternalNavMobile() {
  var href = $('.links-bar > li:first-child > a').attr('href');
  var text = $('.links-bar > li:first-child > a').text();
  $('.links-bar > li:first-child').addClass('is-visible');
  $('.js-mobile-internal').attr('href',href).text(text).removeClass('js-scroll-to');
  $('.links-bar').attr('aria-expanded', 'false');
  $(".js-mobile-arrow").on('click', function(){
    showHideInternalNav(event);
  });
}
