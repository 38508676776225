import $ from 'jquery';
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import Headroom from 'headroom.js';
import 'slick-carousel';
import initInternalNavMobile from './nav/internal-mobile'

const Mangrove = {
  init() {
    this.cache();
    this.events();
    // this.initSal();
    this.initSliders();
    this.initHeadroom();
    this.searchFormHide();
    initInternalNavMobile();
    this.initMobileDropdowns();
    this.initBanner();
    if(this.$body.hasClass('page-template-team') || this.$body.hasClass('single-team') || this.$body.hasClass('tax-project-category') || this.$body.hasClass('page-template-services')) this.initPageScrollPosition();
  },
  cache() {
    this.$doc = $(document);
    this.$win = $(window);
    this.$html = $('html');
    this.$body = $('body');
    this.$mainWrap = $('main');
    this.$header = $('.js-header');
    this.$mobileNav = $('.js-mobile-nav');
    this.$hamburger = $('.js-hamburger');
  },
  events() {
    this.$doc.on('click', '.js-hamburger', event => this.showMobileNav(event));
    this.$doc.on('click', '.js-share', event => this.openShareWindow(event));
    this.$doc.on('click', '.js-text-reveal', event => this.textReveal(event));
    this.$doc.on('click', '.js-scroll-to', event => this.scrollToSection(event));
    this.$doc.on('click', '.js-scroll-to a', event => this.scrollToSection(event));
    this.$doc.on('click', '.js-search', event => this.searchFormReveal(event));
    //this.$doc.on('click', '.js-banner-close', event => this.closeBanner(event));
  },
  initSliders() {
    var givingInfinity = $('.js-slider-giving').data('giving_infinity');
    $('.js-slider-giving').slick({
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      infinite: givingInfinity,
      adaptiveHeight: true,
      asNavFor: '.js-slider-giving-bottom'
    });
    $('.js-slider-giving-bottom').slick({
      slidesToShow: 2,
      slidesToScroll: 1,
      arrows: true,
      prevArrow: '<button class="button_arrow--left"><span>prev<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="6" height="8" viewBox="0 0 6 8" style="enable-background:new 0 0 6 8;" xml:space="preserve"><path d="M0,8C0.4,7,0.8,6,1.4,5.3V2.8C0.9,2,0.4,1.1,0,0h2.1C3.3,1.5,4.6,2.6,6,3.4v1.3C4.6,5.4,3.3,6.5,2.2,8H0z" fill="#DB3328"/></svg></span></button>',
      nextArrow: '<button class="button_arrow--right"><span>more,next<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="6" height="8" viewBox="0 0 6 8" style="enable-background:new 0 0 6 8;" xml:space="preserve"><path d="M0,8C0.4,7,0.8,6,1.4,5.3V2.8C0.9,2,0.4,1.1,0,0h2.1C3.3,1.5,4.6,2.6,6,3.4v1.3C4.6,5.4,3.3,6.5,2.2,8H0z" fill="#DB3328"/></svg></span></button>',
      focusOnSelect: true,
      infinite: givingInfinity,
      asNavFor: '.js-slider-giving'
    });

    //Testimonial slider - START
    var infinity = $('.js-slider-testimonial').data('infinity');
    $('.js-slider-testimonial').slick({
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      adaptiveHeight: true,
      infinite: infinity,
      asNavFor: '.js-slider-testimonial-bottom'
    });
    $('.js-slider-testimonial-bottom').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      prevArrow: '<button class="button_arrow--left"><span>prev<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="6" height="8" viewBox="0 0 6 8" style="enable-background:new 0 0 6 8;" xml:space="preserve"><path d="M0,8C0.4,7,0.8,6,1.4,5.3V2.8C0.9,2,0.4,1.1,0,0h2.1C3.3,1.5,4.6,2.6,6,3.4v1.3C4.6,5.4,3.3,6.5,2.2,8H0z" fill="#DB3328"/></svg></span></button>',
      nextArrow: '<button class="button_arrow--right"><span>more,next<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="6" height="8" viewBox="0 0 6 8" style="enable-background:new 0 0 6 8;" xml:space="preserve"><path d="M0,8C0.4,7,0.8,6,1.4,5.3V2.8C0.9,2,0.4,1.1,0,0h2.1C3.3,1.5,4.6,2.6,6,3.4v1.3C4.6,5.4,3.3,6.5,2.2,8H0z" fill="#DB3328"/></svg></span></button>',
      focusOnSelect: true,
      infinite: infinity,
      asNavFor: '.js-slider-testimonial'
    });
    //Testimonial slider - END

    $('.js-process-slider').slick({
      dots: false,
      arrows: true,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true
    });
    $('.js-mobile-services').slick({
      dots: false,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true
    });
    $('.js-mobile-home').slick({
      dots: false,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true
    });
    $('.js-mobile-values').slick({
      dots: false,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true
    });
  },
  openShareWindow(event) {
    let link = $(event.currentTarget).attr('href');
    if (link.substr(0, 6) != 'mailto') {
      event.preventDefault();
      window.open(link, '', 'width=600, height=400');
    }
  },
  initHeadroom() {
    let theHeader = document.querySelector('.js-header');
    let headroom = new Headroom(theHeader, { offset: 100, tolerance: 5 });
    headroom.init();
  },
  showMobileNav(event) {
    event.preventDefault();
    this.$mobileNav.toggleClass('is-active');
    this.$hamburger.toggleClass('is-active');
    if (this.$mobileNav.hasClass('is-active')) {
      this.$hamburger.attr('aria-pressed', 'true');
    } else {
      this.$hamburger.removeAttr('aria-pressed');
    }
    const banner = $('.js-banner');
    if (banner.hasClass('is-loaded')) {
      this.$mobileNav.css('height', `calc(100% - ${banner.outerHeight()}px)`);
    }
  },
  textReveal(event) {
    event.preventDefault();
    let button = $(event.currentTarget);
    button.children().toggleClass('is-open');
    button.prev().toggleClass('is-open');
  },
  scrollToSection(event) {
    const el = $(event.currentTarget);
    let section = el.attr('href');
    let text = el.text();
    event.preventDefault();

    if(el.parent().parent().hasClass('links-bar')) {
      let navHeight = $('.links-bar').innerHeight();
      $('.js-mobile-internal').attr('href',section).text(text);
      $('.links-bar > li').removeClass('is-visible');
      el.parent().addClass('is-visible');
      $('.links-bar').toggleClass('is-active');
      $(".js-mobile-arrow").toggleClass('is-active');
      $('.js-mobile-internal').removeClass('js-scroll-to');
    }

    if (section) {
      $('html, body').animate({
        scrollTop: $(section).offset().top-25
      }, 1000);
    }
  },
  searchFormReveal(event) {
    const el = $(event.currentTarget);
    el.addClass('is-active');
    $('.js-search-form').addClass('is-active');
    $(document).keydown(function() {
      $("#nav-main-search-input").focus();
    });
  },
  searchFormHide() {
    $(document).mouseup(function(e) {
      var form = $("#nav-main-search");
      if(!form.is(e.target) && form.has(e.target).length === 0){
        form.removeClass('is-active');
        $('.js-search-form').removeClass('is-active');
      }
    });
  },
  initMobileDropdowns() {
    $('.js-mobile-nav .menu-item-has-children').attr('aria-expanded', 'false');
    $('.js-mobile-nav .menu-item-has-children').append( '<span></span>');
    $('.js-mobile-nav .menu-item-has-children > span').click(function() {
      $(this).siblings('.sub-menu').slideToggle(500);
      $(this).parent().toggleClass('submenu-active');
      $(this).parent().attr('aria-expanded',
        $(this).parent().attr('aria-expanded')=='false' ? 'true' : 'false'
      );
    });
  },
  initBanner() {
    const banner = $('.js-banner');
    const main = $('main');
    const header = $('.js-header');
    const mainWrap = main.css('paddingTop');
    const nav = $('.js-mobile-nav');

    $(window).scroll(function() {
      if($(window).scrollTop() > 100) {
        closeBanner(event);
      }
      if($(window).scrollTop() === 0 && !banner.hasClass('is-closed')) {
        openBanner();
      }
    });

    if (banner.length > 0) {
      openBanner();
      $('.js-banner-close').on('click', closeBanner);
      $('.js-banner-close').on('click', function(e) {
        banner.addClass('is-closed');
      });
    }

    function openBanner() {
      banner.addClass('is-loaded');
      const mainPadding = parseInt(mainWrap) + banner.outerHeight();
      header.css('paddingTop', `${banner.outerHeight()}px`);
      main.css('paddingTop', `${mainPadding}px`);
      banner.css('top', 0);
    }

    function closeBanner(event) {
      event.preventDefault();
      banner.removeClass('is-loaded');
      header.css('paddingTop', 0);
      main.css('paddingTop', `${mainWrap}`);
      banner.css('top', `-${banner.outerHeight()}px`);
      nav.css('height', '100%');
    }
  },
  initPageScrollPosition() {
    $(function () {
      var pathName = document.location.pathname;
      var previousPath = document.referrer;
      // console.log('Previous path: ' + previousPath);
      // console.log(previousPath.endsWith('/people/'));

      window.onbeforeunload = function () {
        var scrollPosition = $(document).scrollTop();
        sessionStorage.setItem("scrollPosition_" + pathName, scrollPosition.toString());
      }
      if (previousPath.includes('/project/') || previousPath.includes('/team/')){
        if (sessionStorage["scrollPosition_" + pathName]) {
          $('html, body').animate({
            scrollTop: sessionStorage.getItem("scrollPosition_" + pathName)
          }, 600);
        }
      } else {
        sessionStorage.setItem("scrollPosition_" + pathName, 0);
      }
    });
  }
};

Mangrove.init();
